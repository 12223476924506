// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-bloom-jsx": () => import("./../../../src/pages/bloom.jsx" /* webpackChunkName: "component---src-pages-bloom-jsx" */),
  "component---src-pages-bookshelf-jsx": () => import("./../../../src/pages/bookshelf.jsx" /* webpackChunkName: "component---src-pages-bookshelf-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-podcasts-jsx": () => import("./../../../src/pages/podcasts.jsx" /* webpackChunkName: "component---src-pages-podcasts-jsx" */),
  "component---src-pages-travel-jsx": () => import("./../../../src/pages/travel.jsx" /* webpackChunkName: "component---src-pages-travel-jsx" */),
  "component---src-pages-uses-jsx": () => import("./../../../src/pages/uses.jsx" /* webpackChunkName: "component---src-pages-uses-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

