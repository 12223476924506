import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  .react-toggle--checked .react-toggle-track,
  .react-toggle--checked:hover .react-toggle-track {
        background-color: #000000;
  }
  .react-toggle-track-check {
    height: 14px !important;
  }

  a {
      color: inherit;
      text-decoration: none;
  }

  body {
      -webkit-font-smoothing: antialiased;
      background-color: var(--bg);
  }

  body.light {
    --bg: #F7FAFC;
    --textColor: #F7FAFC;
    --shadow: #1a202c;
    --bgDark: pink;
    --color: #F7FAFC;
    --colorInverted: #1a202c;
    --accent: #333333;
    --shadowLight: #bdc3c7;
    --shadowDark: #2c3e50;
    --bgSection: #3498db;
  }

  body.dark {
    --bg: #000000;
    --bgDark: #000000;
    --shadow: #95a5a6;
    --textColor: #F7FAFC;
    --color: #1a202c;
    --colorInverted: #ECF0F1;
    --accent: #95a5a6;
    --shadowLight: #2c3e50;
    --shadowDark: #2c3e50;
    --bgSection: #1A202C;
  }

  html {
  box-sizing: border-box;
  line-height: 1.15;
}

* {
  margin: 0;
  box-sizing: inherit;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  display: block;
}

img,
video {
  max-width: 100%;
  height: auto;
}

*,
::after,
::before {
  border: 0 solid #e2e8f0;
  box-sizing: inherit;
}

* {
  transition: all 0.5s ease-in-out;
}

.iconHover svg :hover{
    fill: var(--shadowLight);
}

.navLinkHover {
  border-bottom: 0.3rem solid var(--accent) !important;
}

.assistive-text {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.md_content h2, h3 {
	padding: 1rem 0;
}
.md_content h4 {
	padding: 0.5rem 0;
}
.md_content > ul {
	list-style: disc !important;
	margin: inherit;
  padding: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* START OF VSCODE REMARK */
.grvsc-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 1.5rem !important;
  padding-bottom: 1rem;
  border-radius: 0.25rem;
  font-feature-settings: normal;
  margin: 1.5rem 0;
  position: relative;
}

.grvsc-code {
  display: inline-block;
  min-width: 100%;
}

.grvsc-line {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.grvsc-line-highlighted {
  background-color: var(--grvsc-line-highlighted-background-color, transparent);
  box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
}

[data-language]:not([data-language=""])::before {
 border-radius:0 0 4px 4px;
 color:var(--theme-ui-colors-grey-90,#232129);
 font-size:0.75rem;
 font-family:SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
 -webkit-letter-spacing:0.075em;
 -moz-letter-spacing:0.075em;
 -ms-letter-spacing:0.075em;
 letter-spacing:0.075em;
 line-height:1;
 padding:0.25rem 0.5rem;
 position:absolute;
 left:1.5rem;
 text-align:right;
 text-transform:uppercase;
 top: 0rem;
}

[data-language="javascript"]::before {
 content:'js';
 background:#f7df1e;
}
[data-language="js"]::before {
 content:'js';
 background:#f7df1e;
}
[data-language="jsx"]::before {
 content:'jsx';
 background:#61dafb;
}
[data-language="typescript"]::before {
 content:'ts';
 background:#294e80;
 color:var(--theme-ui-colors-white,#ffffff);
}
[data-language="ts"]::before {
 content:'ts';
 background:#294e80;
 color:var(--theme-ui-colors-white,#ffffff);
}
[data-language="tsx"]::before {
 content:'tsx';
 background:#294e80;
 color:var(--theme-ui-colors-white,#ffffff);
}
[data-language="graphql"]::before {
 content:'GraphQL';
 background:#E10098;
 color:var(--theme-ui-colors-white,#ffffff);
}
[data-language="html"]::before {
 content:'html';
 background:#005A9C;
 color:var(--theme-ui-colors-white,#ffffff);
}
[data-language="css"]::before {
 content:'css';
 background:#ff9800;
 color:var(--theme-ui-colors-white,#ffffff);
}
[data-language="mdx"]::before {
 content:'mdx';
 background:#f9ac00;
 color:var(--theme-ui-colors-white,#ffffff);
 font-weight:400;
}
[data-language="shell"]::before {
 content:'shell';
}
[data-language="sh"]::before {
 content:'sh';
}
[data-language="bash"]::before {
 content:'bash';
}
[data-language="yaml"]::before {
 content:'yaml';
 background:#ffa8df;
}
[data-language="markdown"]::before {
 content:'md';
}
[data-language="json"]::before,
[data-language="json5"]::before {
 content:'json';
 background:linen;
}
[data-language="diff"]::before {
 content:'diff';
 background:#e6ffed;
}
[data-language="text"]::before {
 content:'text';
 background:var(--theme-ui-colors-white,#ffffff);
}
[data-language="flow"]::before {
 content:'flow';
 background:#E8BD36;
}
`

export default GlobalStyle
