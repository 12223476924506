import React from 'react'
import PropTypes from 'prop-types'

import GlobalStyle from './src/components/GlobalStyle'
// require('remark-admonitions/styles/classic.css')
require('./plugins/gatsby-remark-admonitions/styles/infima-admonitions.css')

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
)

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment
  const noFooter = element.type.noFooter
  const pageTitle = element.type.pageTitle || null
  return <Layout noFooter={noFooter} title={pageTitle} {...props}>{element}</Layout>
}

wrapRootElement.propTypes = {
  element: PropTypes.node
}
